















































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Watch } from "vue-property-decorator";
import {
  NavBar,
  Row,
  Col,
  Icon,
  Image,
  Progress,
  Popup,
  Button,
  List,
} from "vant";
import ExchangeRecord from "@/views/Mine/MoreServices/CollectionPrinting/ExchangeRecord.vue";
import { printListDtos } from "@/shims.decorator";
import ExternalPrintCollectList from "@/components/CollctPrint/CollectPrintList.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Progress.name]: Progress,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [List.name]: List,
    ExchangeRecord,
    ExternalPrintCollectList,
  },
})
export default class ExternalPrintCollectMini extends Mixins(Mixin) {
  public showPrintRecords = false; // 印花记录
  public activityId = "";
  public printList: Array<printListDtos> = [];
  public selected = "myPrint";
  public list = {
    loading: false,
    finished: false,
  };
  public page = {
    current: 1,
    size: 10,
  };
  mark = false;

  mounted(): void {
    // 非首次进入页面时需要在 mounted 中刷新页面
    if (this.ExternalAccessModule.isExternalAccessReady) {
      this.updateData();
    }
  }

  /**
   * 外部跳转 H5 异步获取 token、channel 的异步请求完成后执行
   * 一定在 mounted 之后
   */
  @Watch("ExternalAccessModule.isExternalAccessReady")
  readyStateChange(isExternalAccessReady: boolean): void {
    if (isExternalAccessReady) {
      this.updateData();
    }
  }

  updateData(): void {
    this.getActivity(true);
  }

  onClickLeft(): void {
    this.jsBridge("back", "", () => {
      this.$router.go(-1);
    });
  }
  // 小程序兑换记录
  showMiniExchangeRecords(): void {
    this.selected = "myRecords";
    this.$nextTick(() => {
      let exchangeRecord = this.$refs["exchange-records"] as ExchangeRecord;
      if (exchangeRecord) {
        exchangeRecord.getRecords();
      }
    });
  }
  getActivity(first: boolean): void {
    if (first) {
      this.page.current = 1;
    } else {
      this.page.current++;
    }
    this.$api.memberApi.printing.myPrintingList(
      {
        pageNum: this.page.current,
        pageSize: this.page.size,
      },
      ({ data }) => {
        if (this.page.current === 1) {
          this.printList = data.records! as Array<printListDtos>;
          this.mark = true;
        } else {
          this.printList = this.printList.concat(
            data.records! as Array<printListDtos>
          );
        }
        if (
          this.ChannelModule.channel === "plat_android" ||
          this.ChannelModule.channel === "plat_ios"
        ) {
          this.jsBridge("isShowShare", "0");
        }
        this.list.loading = false;
        this.list.finished = data.current! >= data.pages!;
      }
    );
  }
  share(): void {
    let url = window.location.href;
    this.jsBridge(
      "share",
      `title%&@/${"集印花活动"}%&@,desc%&@/${""}%&@,picUrl%&@/${""}%&@,url%&@/${url}`
    );
  }
}
