






































































































































































































































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Watch, Vue } from "vue-property-decorator";
import {
  NavBar,
  Row,
  Col,
  Icon,
  Image,
  Progress,
  Popup,
  Button,
  Dialog,
} from "vant";
import { TaskRelationResponses } from "@/shims.decorator";
import wx from "weixin-js-sdk";

@Component({
  components: {
    [Dialog.Component.name]: Dialog.Component,
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Progress.name]: Progress,
    [Popup.name]: Popup,
    [Button.name]: Button,
  },
})
export default class ExternalPrintActDetail extends Mixins(Mixin) {
  public activityId = "";
  public activityState = "";
  public activityInfo: member.selectPrintingDetailResponse = {
    activityBackColor: "",
    activityDescription: "",
    activityId: "",
    activityName: "",
    activityOverdue: false,
    activityStart: false,
    activityStartTime: "",
    activityState: "",
    exchangeEndTime: "",
    exchangeOverdue: false,
    finishPrintingNumber: 0,
    finishTaskNumber: 0,
    floatIconUrl: "",
    guideImageUrl: "",
    headImageUrl: "",
    printingNumber: 0,
    shareDescription: "",
    shareImageUrl: "",
    taskNumber: 0,
  };
  showFinish = false;
  public taskList: Array<TaskRelationResponses> = [];
  public showActDesc = false; // 活动说明
  showDownload = false;
  public browseSuccess = false; // 浏览成功
  public isOuterChain = true;
  public currentOuterTaskId = "";
  public title = "";
  public signTaskId = "";
  public qrCodeUrl = "";

  /**
   * 外部跳转 H5 异步获取 token、channel 的异步请求完成后执行
   * 一定在 mounted 之后
   */
  @Watch("ExternalAccessModule.isExternalAccessReady")
  readyStateChange(isExternalAccessReady: boolean): void {
    if (isExternalAccessReady) {
      this.updateData();
    }
  }

  mounted(): void {
    // 非首次进入页面时需要在 mounted 中刷新页面
    if (this.ExternalAccessModule.isExternalAccessReady) {
      this.updateData();
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  changeRoute(val: any, oldVal: any): void {
    if (this.isOuterChain && this.currentOuterTaskId) {
      this.getTaskId(this.currentOuterTaskId);
    }
  }
  getTaskId(id: string): void {
    if (id) {
      // this.$toast(id);
      console.log(id, "teskId");
      // this.$api.memberApi.printing.browseTask({ taskId: id }, ({ data }) => {
      //   if (data.success) {
      //     this.getPrintActInfo();
      //   }
      // });
    }
  }

  updateData(): void {
    window.scrollTo(0, 0);
    this.activityId = this.$route.query.id as string;
    this.activityState = this.$route.query.state as string;
    this.getPrintActInfo();
  }

  onClickLeft(): void {
    if (this.ChannelModule.channel === "plat_h5") {
      this.$router.go(-1);
    } else if (
      this.ChannelModule.channel === "plat_ios" ||
      this.ChannelModule.channel === "plat_android"
    ) {
      this.jsBridge("back", "");
    }
  }
  share(): void {
    let url = `${location.origin}${location.pathname}#/print-activity?id=${this.activityId}`;
    this.jsBridge(
      "share",
      `title%&@/${this.activityInfo.activityName}%&@,desc%&@/${this.activityInfo.shareDescription}%&@,picUrl%&@/${this.activityInfo.shareImageUrl}%&@,url%&@/${url}`
    );
  }
  getPrintActInfo(): void {
    this.$api.memberApi.printing.selectPrintingDetail(
      {
        activityId: this.activityId,
      },
      ({ data }) => {
        window.document.title = data.activityName;
        this.activityInfo = data;
        if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          window.getScanCode = this.getScanCode;
        }
        this.taskList =
          data.taskActivityRelationResponses as Array<TaskRelationResponses>;
        if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          this.jsBridge("isShowShare", "1");
          window.startShare = this.share;
          window.getTaskId = this.getTaskId;
        }
        if (!data.activityStart) {
          this.$toast("活动未开始");
        }
      }
    );
  }
  getScanCode(status: string): void {
    if (status === this.qrCodeUrl) {
      this.$api.memberApi.printing.browseTask(
        { taskId: this.signTaskId },
        ({ data }) => {
          if (data.success) {
            this.$toast("打卡成功");
            this.getPrintActInfo();
          }
        }
      );
    }
  }
  gotoDrawAct(): void {
    if (
      this.activityInfo.finishPrintingNumber >=
        this.activityInfo.infoResponse!.purposeNumber! &&
      this.activityInfo.drawStatus === "1"
    ) {
      this.$router.push(
        `/lottery?id=${this.activityInfo.infoResponse!.purposeId}`
      );
    } else {
      this.$toast(
        `集齐${
          this.activityInfo.infoResponse!.purposeNumber
        }枚印花，才可以抽奖哦！`
      );
    }
  }
  taskImageUrl(taskType: string): string {
    let url = "";
    if (taskType == "11") {
      url = require("@/assets/images/CollectPrint/daka.webp");
    } else if (taskType === "06") {
      url = require("@/assets/images/CollectPrint/liulan.webp");
    } else if (taskType === "08" || taskType === "09") {
      url = require("@/assets/images/CollectPrint/goupiao.webp");
    } else if (taskType === "10") {
      url = require("@/assets/images/CollectPrint/duihuan.webp");
    }
    return url;
  }
  taskStatusName(
    taskStatus: string,
    taskType: string,
    taskFinishFlag: boolean,
    isOnline: number
  ): {
    name: string;
  } {
    let obj = {
      name: "",
    };
    if (!this.activityInfo.activityOverdue) {
      if (taskStatus === "1") {
        if (taskType == "11") {
          if (taskFinishFlag) {
            obj.name = "已完成";
          } else {
            obj.name = isOnline === 1 ? "扫一扫" : "去打卡";
          }
        } else if (taskType === "06") {
          if (taskFinishFlag) {
            obj.name = "已完成";
          } else {
            obj.name = "去浏览";
          }
        } else if (taskType === "10") {
          if (taskFinishFlag) {
            obj.name = "已完成";
          } else {
            obj.name = "去兑换";
          }
        } else if (taskType === "09" || taskType === "08") {
          if (taskFinishFlag) {
            obj.name = "已完成";
          } else {
            obj.name = "去购票";
          }
        }
      } else if (taskStatus === "2") {
        obj.name = "已结束";
      }
    } else if (this.activityInfo.activityOverdue) {
      obj.name = "已结束";
    }

    return obj;
  }
  todoTask(
    item: TaskRelationResponses,
    taskId: string,
    taskType: string,
    browseObj: member.CloundAdvertInsertOrUpdateRpcRequest,
    rangeType: string,
    ruleId: string,
    isOnline: number,
    taskFinishFlag: boolean
  ): void {
    if (!taskFinishFlag) {
      switch (taskType) {
        case "11":
          // 打卡/扫一扫
          if (isOnline === 1) {
            // 线下打卡-扫一扫
            if (
              this.ChannelModule.channel === "plat_ios" ||
              this.ChannelModule.channel === "plat_android"
            ) {
              this.signTaskId = taskId;
              this.qrCodeUrl = item.qrCodeUrl!;
              this.jsBridge("scan", "");
            } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
              // 小程序调起微信扫一扫
              wx.ready(() => {
                wx.scanQRCode({
                  needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果
                  success: function (resp: any) {
                    let data = resp.resultStr; // 当needResult 为 1 时，扫码返回的结果
                    // window.open(data);
                    // 处理自己的逻辑
                    this.getScanCode(resp.resultStr);
                  },
                });
              });
            } else {
              // H5环境提示下载App
              this.showDownload = true;
            }
          } else {
            // 线上打卡
            this.$api.memberApi.printing.browseTask(
              { taskId: taskId },
              ({ data }) => {
                if (data.success) {
                  this.showFinish = true;
                  this.getPrintActInfo();
                }
              }
            );
          }
          break;
        case "08":
        case "09":
          // 跳演出列表/演出详情页
          this.goShowDetails(rangeType, item);
          break;
        case "06":
          // 浏览页面
          this.$api.memberApi.printing.browseTask(
            { taskId: taskId },
            ({ data }) => {
              if (data.success) {
                this.goBrowse(item);
                this.getPrintActInfo();
              }
            }
          );
          break;
        case "10":
          // 积分兑换
          this.goIntergral(rangeType, item);
      }
    }
  }
  goShowDetails(type: string, item: TaskRelationResponses): void {
    switch (type) {
      case "1":
        // 全部
        if (this.ChannelModule.channel === "plat_h5") {
          this.$router.push(`/classify`);
        } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
          wx.miniProgram.navigateTo({
            url: `/pagesIndex/performanceScreening/performanceScreening`,
          });
        } else {
          this.jsBridge("goClassify", `0`);
        }
        break;
      case "2":
        // 指定分类
        if (this.ChannelModule.channel === "plat_h5") {
          this.$router.push(`/classify?categoryId=${item.ruleId}`);
        } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
          wx.miniProgram.navigateTo({
            url: `/pagesIndex/performanceScreening/performanceScreening?id=${item.ruleId}`,
          });
        } else {
          this.jsBridge(
            "goClassify",
            `${item.ruleId === null ? "0" : item.ruleId}`
          );
        }
        break;
      case "3":
        // 指定项目
        if (this.ChannelModule.channel === "plat_h5") {
          this.goToProjectDetail(item.ruleId);
        } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
          wx.miniProgram.navigateTo({
            url: `/pagesIndex/performanceScreening/performanceScreening?id=${item.ruleId}`,
          });
        } else {
          this.jsBridge(
            "goProDetail",
            `productId/${item.ruleId},theaterId/40,taskId/${item.taskId}`
          );
        }
        break;
    }
  }
  goBrowse(item: TaskRelationResponses): void {
    switch (item.insertOrUpdatetRpcRequest.linkType) {
      case "01":
        // 演出详情页
        if (this.ChannelModule.channel === "plat_h5") {
          this.$router.push(
            `/projectdetail/${
              item.insertOrUpdatetRpcRequest.linkProject!.productId
            }/null`
          );
        } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
          wx.miniProgram.navigateTo({
            url: `/pagesIndex/performDetail/performDetail?id=${
              item.insertOrUpdatetRpcRequest.linkProject!.productId
            }`,
          });
        } else {
          this.jsBridge(
            "goProDetail",
            `productId/${
              item.insertOrUpdatetRpcRequest.linkProject!.productId
            },theaterId/${
              item.insertOrUpdatetRpcRequest.linkProject!.theaterId
            },taskId/${item.taskId}`
          );
        }
        break;
      case "02":
        // 外链
        window.location.href = item.url!;
        break;
      case "03":
        // 云剧院详情
        if (
          this.ChannelModule.channel === "plat_h5" ||
          this.ChannelModule.channel === "plat_wechat_miniapp"
        ) {
          this.$router.push(
            `/cloud-theatre-details?id=${
              item.insertOrUpdatetRpcRequest.commodityMessage!.commodityInfoId
            }`
          );
        } else {
          this.jsBridge(
            "goCloudDetail",
            `id/${item.insertOrUpdatetRpcRequest.linkVideo!.id},taskId/${
              item.taskId
            }`
          );
        }
        break;
      case "04":
        // 演出详情列表/演出分类
        if (this.ChannelModule.channel === "plat_h5") {
          this.$router.push(
            `/classify?categoryId=${item.insertOrUpdatetRpcRequest.navTypeId}`
          );
        } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
          wx.miniProgram.navigateTo({
            url: `/pagesIndex/performanceScreening/performanceScreening?id=${item.insertOrUpdatetRpcRequest.navTypeId}`,
          });
        } else {
          console.log("goClassify1");
          this.jsBridge(
            "goClassify",
            `${
              item.insertOrUpdatetRpcRequest.navTypeId
                ? item.insertOrUpdatetRpcRequest.navTypeId
                : "0"
            }`
          );
        }
        break;
      case "05":
        // 商品详情
        if (this.ChannelModule.channel === "plat_h5") {
          this.$router.push(
            `/mall/details?commodityId=${
              item.insertOrUpdatetRpcRequest.commodityMessage!.commodityInfoId
            }`
          );
        } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
          wx.miniProgram.navigateTo({
            url: `/pagesMall/goodsDetails/goodsDetails?commodityInfoId=${
              item.insertOrUpdatetRpcRequest.commodityMessage!.commodityInfoId
            }`,
          });
        } else {
          this.jsBridge(
            "goGoodDetail",
            `commodityInfoId/${
              item.insertOrUpdatetRpcRequest.commodityMessage!.commodityInfoId
            },theaterId/40,taskId/${item.taskId}`
          );
        }
        break;
      case "06":
        // 商城首页
        if (this.ChannelModule.channel === "plat_h5") {
          this.$router.push(`/mall`);
        } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
          wx.miniProgram.switchTab({
            url: `/index/mall`,
          });
        } else {
          this.jsBridge("goStore", "");
        }
        break;
      case "07":
        // 云剧院模块
        if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          this.jsBridge("goCloud", "");
        } else if (this.ChannelModule.channel === "plat_h5") {
          this.$router.push(`/mine/download-app`);
        } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
          Dialog.confirm({
            confirmButtonText: "复制",
            title: "复制链接",
            message: `<div>
            <span>
            复制下载地址到浏览器打开
            </span>
            <span>
            https://a.app.qq.com/o/simple.jsp?pkgname=com.poly.mobile&android_scheme=polyt://&ios_scheme=polyt://
            </span>
              </div>
            `,
          })
            .then(() => {
              const input = document.createElement("input"); // 直接构建input
              input.value =
                "https://a.app.qq.com/o/simple.jsp?pkgname=com.poly.mobile&android_scheme=polyt://&ios_scheme=polyt://"; // 设置内容
              document.body.appendChild(input); // 添加临时实例
              input.select(); // 选择实例内容
              document.execCommand("Copy"); // 执行复制
              if (document.execCommand("Copy")) {
                this.$toast("复制成功");
              } else {
                this.$toast("复制失败");
              }

              document.body.removeChild(input); // 删除临时实例
            })
            .catch(() => {
              // on cancel
            });
        }
        break;
      case "08":
        //"领券中心(跳转到领券中心模块)"
        if (this.ChannelModule.channel === "plat_wechat_miniapp") {
          wx.miniProgram.navigateTo({
            url: `/pagesCenter/couponCenter/couponCenter`,
          });
        } else if (this.ChannelModule.channel === "plat_h5") {
          this.$router.push("/coupon-center");
        } else if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          this.jsBridge("goCouponCenter", `theaterId/${40}`);
        }
        break;
      case "09":
        this.$router.push(`/limit-coupon`);
        //"限时抢券(跳转到限时抢券模块)"
        break;
      case "10":
        // 搜索页
        if (this.ChannelModule.channel === "plat_h5") {
          this.$router.push(
            `/search/show?keyword=${item.insertOrUpdatetRpcRequest.keyword}`
          );
        } else if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          this.jsBridge("goSearch", item.insertOrUpdatetRpcRequest.keyword);
        } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
          wx.miniProgram.navigateTo({
            url: `/pagesIndex/search/search?content=${item.insertOrUpdatetRpcRequest.keyword}&type=performance`,
          });
        }
        break;
      case "11":
        // 活动报名列表页
        this.$router.push(`/enter-activity`);
        break;
      case "12":
        //  抽奖活动页
        this.$router.push(
          `/lottery?id=${
            item.insertOrUpdatetRpcRequest.advertisingMessage!.advertisingId
          }`
        );
        break;
      case "13":
        // 裂变活动页
        this.$router.push(
          `/fission-activity?id=${
            item.insertOrUpdatetRpcRequest.advertisingMessage!.advertisingId
          }`
        );
        break;
      case "14":
        // 限时抢购页
        this.$router.push(`/flash-sale`);
        break;
      case "15":
        // 集印花
        this.$router.push(
          `/print-activity?id=${
            item.insertOrUpdatetRpcRequest.advertisingMessage!.advertisingId
          }`
        );
        break;
    }
  }
  goIntergral(rangeType: string, item: TaskRelationResponses): void {
    switch (rangeType) {
      case "1":
        if (this.ChannelModule.channel === "plat_h5") {
          this.$router.push(`/mall?activityOrderId=${item.activityId}`);
        } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
          wx.miniProgram.switchTab({
            url: `/index/mall`,
          });
        } else {
          this.jsBridge("goStore", "");
        }
        break;
      case "2":
        if (this.ChannelModule.channel === "plat_h5") {
          this.$router.push(
            `/mall/details?commodityId=${item.ruleId}&activityOrderId=${item.activityId}`
          );
        } else if (this.ChannelModule.channel === "plat_wechat_miniapp") {
          wx.miniProgram.navigateTo({
            url: `/pagesMall/goodsDetails/goodsDetails?commodityInfoId=${item.ruleId}`,
          });
        } else {
          this.jsBridge("goGoodDetail", item.ruleId);
        }
        break;
    }
  }
  downloadApp(): void {
    // 下载app
    this.$router.push("/mine/download-app");
  }
}
